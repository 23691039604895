import styles from '@styles/Home.module.scss';

interface Props {
    title: string;
    text?: string;
}

const Title = ({ title, text }: Props) => {
    return (
        <div className={styles.title_area}>
            <h3 className={styles.title}>{title}</h3>
            {text && <h6 className={styles.text}>{text}</h6>}
        </div>
    );
};

export default Title;
