import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import useTranslation from '@modules/hooks/useTranslation';
import Title from '@feature/home/components/Title';
import styles from '@styles/Home.module.scss';

const GuideSection = () => {
    const { locale } = useRouter();
    const { t } = useTranslation('home');
    const { t: guide } = useTranslation('guide');

    const [state, setState] = useState<'STS' | 'TTS'>('STS');
    const [step, setStep] = useState(0);

    useEffect(() => {
        setStep(0);
    }, [state]);

    return (
        <section className={styles.guide}>
            <div className={styles.inner}>
                <Title title={t('guideSection.title')} />

                <div className={styles.feature_list_wrap}>
                    <ul className={styles.feature_list}>
                        <li className={`${styles.feature_item} ${state === 'STS' ? styles.active : ''}`}>
                            <button type="button" onClick={() => setState('STS')}>
                                <strong>1. {t('guideSection.STS')}</strong>
                                &nbsp;(Speech To Speech)
                            </button>
                        </li>
                        <li className={`${styles.feature_item} ${state === 'TTS' ? styles.active : ''}`}>
                            <button type="button" onClick={() => setState('TTS')}>
                                <strong>2. {t('guideSection.TTS')}</strong>
                                &nbsp;(Text To Speech)
                            </button>
                        </li>
                    </ul>
                </div>

                <div className={styles.step_area}>
                    <ul className={styles.step_list}>
                        {[...guide(`contentList.${state}`)]
                            .filter((_, index) => index !== (state === 'STS' ? 1 : 0))
                            .map((item, index) => (
                                <li key={`guide-${index}`} className={styles.step_item}>
                                    <button
                                        type="button"
                                        onClick={() => setStep(index)}
                                        className={index === step ? styles.active : ''}
                                    >
                                        <span className={styles.step}>Step {index + 1}</span>
                                        <span className={styles.title}>{item.title}</span>
                                        <span className={styles.text}>{item.text}</span>
                                    </button>
                                </li>
                            ))}
                    </ul>
                    <img
                        src={`/images/home/guide/${state}/${step}_${locale}.webp`}
                        alt=""
                        loading="eager"
                        height={602}
                        className={styles.preview}
                    />
                </div>
            </div>
        </section>
    );
};

export default GuideSection;
