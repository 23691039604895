import Slider from 'react-slick';
import useTranslation from '@modules/hooks/useTranslation';
import { Mobile, PC } from '@components/MediaQuery';
import Title from '@feature/home/components/Title';
import styles from '@styles/Home.module.scss';

interface Props {
    refForAni: (element: HTMLDivElement) => HTMLDivElement;
}

const BenefitsSection = ({ refForAni }: Props) => {
    const { t } = useTranslation('home');

    const settings = {
        infinite: false,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: true,
        speed: 500,
        cssEase: 'linear',
    };

    return (
        <section className={`${styles.section} ${styles.benefits}`}>
            <div ref={refForAni} className={styles.inner}>
                <Title title={t('benefitsSection.title')} />

                <PC>
                    <ul className={styles.benefit_list}>
                        {t('benefitsSection.benefitList').map(
                            (item: { image: string; title: string; text: string }, index: number) => (
                                <li key={`benefit-${index}`} className={styles.benefit_item}>
                                    <img src={item.image} alt="" width={64} height={64} />
                                    <span className={styles.title}>{item.title}</span>
                                    <span>{item.text}</span>
                                </li>
                            ),
                        )}
                    </ul>
                </PC>

                <Mobile>
                    <Slider {...settings}>
                        {t('benefitsSection.benefitList').map(
                            (item: { image: string; title: string; text: string }) => (
                                <div>
                                    <div className={styles.benefit_item}>
                                        <img src={item.image} alt="" width={64} height={64} />
                                        <span className={styles.title}>{item.title}</span>
                                        <span>{item.text}</span>
                                    </div>
                                </div>
                            ),
                        )}
                    </Slider>
                </Mobile>
            </div>
        </section>
    );
};

export default BenefitsSection;
