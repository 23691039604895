import Title from '@feature/home/components/Title';
import useTranslation from '@modules/hooks/useTranslation';
import styles from '@styles/Home.module.scss';

interface Props {
    refForAni: (element: HTMLDivElement) => HTMLDivElement;
}

const UsageSection = ({ refForAni }: Props) => {
    const { t } = useTranslation('home');

    return (
        <section className={`${styles.section} ${styles.usage}`}>
            <div ref={refForAni} className={styles.inner}>
                <Title title={t('usageSection.title')} />

                <div className={styles.list_wrap}>
                    <ul className={styles.review_list}>
                        {t('usageSection.reviewList')
                            .concat(t('usageSection.reviewList'))
                            .map((item: { title: string; text: string; writer: string }, index: number) => (
                                <li key={`review-${index}`} className={styles.review_item}>
                                    <span className={styles.title}>“{item.title}”</span>
                                    <span className={styles.text}>{item.text}</span>
                                    <span dangerouslySetInnerHTML={{ __html: item.writer }} className={styles.writer} />
                                </li>
                            ))}
                    </ul>
                </div>

                <div className={styles.list_wrap}>
                    <ul className={styles.usage_list}>
                        {t('usageSection.usageList')
                            .concat(t('usageSection.usageList'))
                            .map((item: { image: string; text: string }, index: number) => (
                                <li key={`usage-${index}`} className={styles.usage_item}>
                                    <img src={item.image} alt="" width={246} height={200} />
                                    <span>{item.text}</span>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default UsageSection;
