import { useState } from 'react';
import Slider from 'react-slick';
import useTranslation from '@modules/hooks/useTranslation';
import { Mobile, PC } from '@components/MediaQuery';
import Title from '@feature/home/components/Title';
import styles from '@styles/Home.module.scss';

interface Props {
    refForAni: (element: HTMLDivElement) => HTMLDivElement;
}

const VoiceSection = ({ refForAni }: Props) => {
    const { t, locale } = useTranslation('home');

    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        infinite: false,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: true,
        speed: 700,
        cssEase: 'linear',
        afterChange: (i: number) => {
            setCurrentSlide(i);
        },
    };

    return (
        <section className={`${styles.section} ${styles.voice}`}>
            <div ref={refForAni} className={styles.inner}>
                <Title title={t('voiceSection.title')} />

                <PC>
                    <ul className={styles.voice_list}>
                        <li className={styles.voice_item}>
                            <div className={styles.preview_area}>
                                <img src={`/images/home/voice/basic_${locale}.svg`} alt="" width={264} />
                            </div>
                            <div className={styles.text_area}>
                                <h4>{t('voiceSection.basic.title')}</h4>
                                <span>{t('voiceSection.basic.text')}</span>
                            </div>
                        </li>
                        <li className={styles.voice_item}>
                            <div className={styles.text_area}>
                                <h4>{t('voiceSection.open.title')}</h4>
                                <span>{t('voiceSection.open.text')}</span>
                            </div>
                            <div className={styles.preview_area}>
                                <img src={`/images/home/voice/open_${locale}.webp`} alt="" width={326} height={205} />
                            </div>
                        </li>
                        <li className={styles.voice_item}>
                            <div className={styles.preview_area}>
                                <img src={`/images/home/voice/custom_${locale}.webp`} alt="" width={264} />
                            </div>
                            <div className={styles.text_area}>
                                <h4>{t('voiceSection.custom.title')}</h4>
                                <span>{t('voiceSection.custom.text')}</span>
                            </div>
                        </li>
                    </ul>
                </PC>

                <Mobile>
                    <Slider {...settings}>
                        <div>
                            <div className={`${styles.voice_item} ${currentSlide === 0 ? styles.active : ''}`}>
                                <div className={styles.preview_area}>
                                    <img
                                        src={`/images/home/voice/basic_${locale}.svg`}
                                        alt=""
                                        width={224}
                                        height={258}
                                    />
                                </div>

                                <div className={styles.text_area}>
                                    <h4>{t('voiceSection.basic.title')}</h4>
                                    <span>{t('voiceSection.basic.text')}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={`${styles.voice_item} ${currentSlide === 1 ? styles.active : ''}`}>
                                <div className={styles.preview_area}>
                                    <img src={`/images/home/voice/open_${locale}.webp`} alt="" width={205} />
                                </div>
                                <div className={styles.text_area}>
                                    <h4>{t('voiceSection.open.title')}</h4>
                                    <span>{t('voiceSection.open.text')}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={`${styles.voice_item} ${currentSlide === 2 ? styles.active : ''}`}>
                                <div className={styles.preview_area}>
                                    <img src={`/images/home/voice/custom_${locale}.webp`} alt="" width={224} />
                                </div>
                                <div className={styles.text_area}>
                                    <h4>{t('voiceSection.custom.title')}</h4>
                                    <span>{t('voiceSection.custom.text')}</span>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </Mobile>
            </div>
        </section>
    );
};

export default VoiceSection;
