import { useEffect, useLayoutEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { getModalClose } from '@modules/utils/cookie';
import { IsNoticeModalOpen } from '@modules/state/home';
import { PC } from '@components/MediaQuery';
import { IsMobileSize } from '@modules/state/common';
import LadingSection from '@feature/home/LandingSection';
import FeatureSection from '@feature/home/FeatureSection';
import GuideSection from '@feature/home/GuideSection';
import UsageSection from '@feature/home/UsageSection';
import BenefitsSection from '@feature/home/BenefitsSection';
import VoiceSection from '@feature/home/VoiceSection';
import Scalability from '@feature/home/Scalability';
import EndingSection from '@feature/home/EndingSection';
import styles from '@styles/Home.module.scss';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const Home = () => {
    const isMobile = useRecoilValue(IsMobileSize);

    const Notice = dynamic(() => import('@components/modal/Notice'));

    const [isNoticeModalOpen, setIsNoticeModalOpen] = useRecoilState(IsNoticeModalOpen);

    useLayoutEffect(() => {
        setIsNoticeModalOpen(!(getModalClose('UNM') === 'false'));
    }, []);

    const contents = useRef<HTMLDivElement[]>([]);

    useEffect(() => {
        let observer: IntersectionObserver;
        if (contents.current && contents.current.length) {
            // eslint-disable-next-line no-restricted-syntax
            for (const item of contents.current) {
                observer = new IntersectionObserver(
                    (e) => {
                        e.forEach((item) => {
                            const target = item.target as HTMLElement;
                            if (item.isIntersecting) {
                                target.style.animationName = styles['appear-bottom'];
                            }
                        });
                    },
                    { threshold: 0.1 },
                );
                observer.observe(item);
            }
        }
        return () => {
            observer.disconnect();
        };
    }, [contents]);

    return (
        <>
            {isNoticeModalOpen && <Notice />}

            <div className={styles.wrap}>
                <LadingSection />

                <Link href="/labs/shorts">
                    <a className={styles.banner}>
                        <img src={`/images/labs/shorts/ad-banner${isMobile ? '-mobile' : ''}.webp`} alt="" />
                    </a>
                </Link>

                <FeatureSection refForAni={(element: HTMLDivElement) => (contents.current[0] = element)} />

                <PC>
                    <GuideSection />
                </PC>

                <UsageSection refForAni={(element: HTMLDivElement) => (contents.current[1] = element)} />
                <VoiceSection refForAni={(element: HTMLDivElement) => (contents.current[2] = element)} />
                <BenefitsSection refForAni={(element: HTMLDivElement) => (contents.current[3] = element)} />
                <Scalability refForAni={(element: HTMLDivElement) => (contents.current[4] = element)} />
                <EndingSection />
            </div>
        </>
    );
};

export default Home;
