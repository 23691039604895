import useTranslation from '@modules/hooks/useTranslation';
import styles from '@styles/Home.module.scss';
import VOLI from '../../../public/VOLI-white.svg';

const EndingSection = () => {
    const { t } = useTranslation('home');

    return (
        <section className={styles.ending}>
            <span className={styles.title}>{t('endingSection.title')}</span>
            <VOLI width={162} />
            <div className={styles.btn_area}>
                <button type="button">{t('endingSection.start')}</button>
                <button
                    type="button"
                    name="contact"
                    title={t('endingSection.contact')}
                    data-tally-layout="modal"
                    data-tally-open="wkl8jM"
                    data-tally-emoji-text="👋"
                    data-tally-emoji-animation="wave"
                >
                    {t('endingSection.contact')}
                </button>
            </div>
        </section>
    );
};

export default EndingSection;
