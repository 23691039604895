import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';
import useTranslation from '@modules/hooks/useTranslation';
import { User } from '@modules/state/user';
import styles from '@styles/Home.module.scss';

const Video = ({ locale }: { locale: 'ko' | 'en' }) => {
    const [isVideoMuted, setIsVideoMuted] = useState(true);

    return (
        <div className={styles.video_area}>
            <video autoPlay loop muted={isVideoMuted} playsInline className={styles.video}>
                <source
                    src={`${process.env.NEXT_PUBLIC_AWS_URL}/landing/VOLI_Official_${locale}.webm`}
                    type="video/webm"
                />
                <source
                    src={`${process.env.NEXT_PUBLIC_AWS_URL}/landing/VOLI_Official_${locale}.mp4`}
                    type="video/mp4"
                />
            </video>
            <button
                type="button"
                name={isVideoMuted ? 'muted' : ''}
                onClick={() => setIsVideoMuted(!isVideoMuted)}
                className={styles.control_btn}
            >
                {isVideoMuted ? 'Sound' : 'OFF'}
            </button>
        </div>
    );
};

const LandingSection = () => {
    const { t } = useTranslation('home');

    const router = useRouter();
    const { locale } = router;

    const user = useRecoilValue(User);

    const handleStartClick = () => {
        if (user.accessToken) {
            router.push('/studio/text-to-speech');
        } else {
            router.push('/login');
        }
    };

    return (
        <section className={styles.landing}>
            <div className={styles.inner}>
                <h4>{t('subTitle')}</h4>
                <h2>{t('title')}</h2>
                <h3>{t('text')}</h3>

                <button type="button" aria-label={t('CTA')} onClick={handleStartClick} className={styles.start_btn}>
                    {t('CTA')}
                </button>

                {locale === 'ko' && <Video locale="ko" />}
                {locale === 'en' && <Video locale="en" />}
            </div>
        </section>
    );
};

export default LandingSection;
