import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import useTranslation from '@modules/hooks/useTranslation';
import { User } from '@modules/state/user';
import Title from '@feature/home/components/Title';
import styles from '@styles/Home.module.scss';

interface Props {
    refForAni: (element: HTMLDivElement) => HTMLDivElement;
}
const FeatureSection = ({ refForAni }: Props) => {
    const router = useRouter();

    const { t } = useTranslation('home');

    const user = useRecoilValue(User);
    const handleCTAClick = async () => {
        if (user.accessToken) {
            await router.push('/studio/text-to-speech');
            return;
        }
        await router.push('/login');
    };

    return (
        <section className={`${styles.section} ${styles.feature}`}>
            <div ref={refForAni} className={styles.inner}>
                <Title title={t('featureSection.title')} />

                <ul className={styles.feature_list}>
                    {t('featureSection.featureList').map(
                        (
                            item: {
                                title: string;
                                subTitle: string;
                                text: string;
                                features: { image: string; text: string }[];
                            },
                            index: number,
                        ) => (
                            <li key={`feature-${index}`} className={styles.feature_item}>
                                <div className={styles.text_area}>
                                    <span className={styles.text}>{item.text}</span>
                                    <p className={styles.title}>{item.title}</p>

                                    <span className={styles.sub_title}>{item.subTitle}</span>
                                </div>

                                <button type="button" onClick={handleCTAClick} className={styles.cta_btn}>
                                    {t('featureSection.experiencing')}
                                </button>

                                <ul className={styles.feature_details}>
                                    {item.features.map((feature, index: number) => (
                                        <li key={`feature-detail-${index}`} className={styles.feature_detail_item}>
                                            <img src={feature.image} alt="" width={32} height={32} />
                                            <span>{feature.text}</span>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ),
                    )}
                </ul>
            </div>
        </section>
    );
};

export default FeatureSection;
